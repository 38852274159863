import CreateDealerApplication from '~/components/modal-windows/CreateDealerApplication.vue'
import constants from '~/constants'
import YandexMetrikaGoal from '~/enums/yandex-metrika-goal'
import type { OldBanner, BannerCard } from '~/types/banners'

export const bannerCards: BannerCard[] = [
  {
    buttonText: 'Подробнее',
    description: 'Составление программ и\xA0подбор БАД',
    name: 'Консультации специалистов',
    path: constants.categories.consultations.path,
    yandexMetrikaGoal: YandexMetrikaGoal.IndexBannersSectionBannerCardsSpecialists
  },
  {
    buttonText: 'Пройти тест',
    description: 'Пройдите тест от\xA0наших экспертов и\xA0получите подборку БАД',
    name: 'Подбор БАД',
    url: constants.testsUrl,
    yandexMetrikaGoal: YandexMetrikaGoal.IndexBannersSectionBannerCardsTests
  }
]

export const bannersForDistributor: OldBanner[] = [
  {
    backgroundColor: '#00bc7d',
    buttonColor: 'white',
    buttonTitle: 'Оставить заявку',
    color: 'white',
    modalWindow: CreateDealerApplication,
    picture: { src: '/images/pages/distributor/banners/1.jpg', src2: '/images/pages/distributor/banners/1.jpg' },
    title: 'Айтаб\xA0— крупнейший дистрибьютор качественных БАД'
  }
]
